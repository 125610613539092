/* write your css here */
@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.img {
  width: 100%;
}

.registerbuttoncss{
  display: block;
}
.mediabuttonphone{
  display: none;
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
   
}
.registertoday1{
  width: 150px;
height: 41px;
left: 122px;
bottom: 135px;

background: #5274E3;
border-radius: 30px;
}
.footer-image{
  display: none;
}
.required:after {
    content:" *";
    color: red;
  }

.lining{
        white-space: nowrap;

}

.free{
 
width: 178.11px;
height: 96px;


border: 1px solid #000000;
border-radius: 30px;
}
.free p{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 26.04px;
/* line-height: 10px; */
/* or 37% */

margin: 0 auto;
text-align: center;
/* text-indent: 25px; */
text-transform: uppercase;

color: #5274E3;
}

.fontproperty{
    font-family: 'HeadlandOne';
font-style: normal;
font-weight: 400;
font-size: 19.04px;
line-height: 10px;
color: #000000;
}
.orange{
   
    position: absolute;
    width: 754px;
    height: 625px;
    left: 455px;
    top: -262px;
    z-index: -10;
    
    background: #F03031;
    filter: blur(287px);
}
.grad{
    flex: 1 1;
    position: relative;
    height: 35rem;
    /* margin-top: 100px; */
}
.green{
    position: absolute;
width: 676px;
height: 624px;
left: 1058px;
top: 209px;

background: #2AC44B;
filter: blur(397px);
}
.blue{
    position: absolute;
width: 868px;
height: 784px;
left: -461px;
top: 412px;

background: #2A4BB7;
filter: blur(417px);

}

.ellipse{
    
position: absolute;
width: 676px;
height: 624px;
left: 1087px;
top: -46px;

background: #1AB5DB;
filter: blur(397px);
}

.box{
    /* position: absolute; */
    top:1rem;
    right: 0;
width: 150px;
height: 70px;
/* top: 50%;
left: 50%; */
border: 5px solid #000000;
border-radius: 30px;
text-align: center;
align-items: center;
z-index: 10;
}
.button{
width: 100px;
height: 33px;
text-align: center;
/* margin-top: 10px; */

background: rgba(0, 0, 0, 0.99);
box-shadow: 0px 0.73544px 1.47088px rgba(16, 24, 40, 0.05);
border-radius: 5.88352px;
color:white;
}
.heading{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 66px;
line-height: 90px;
letter-spacing: -0.02em;

color: rgba(0, 0, 0, 0.99);
}
.heading1{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 66px;
line-height: 90px;
letter-spacing: -0.02em;
color: #325DEB;
/* color: rgba(0, 0, 0, 0.99); */
}
.subheading{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 27px;
line-height: 140%;
/* or 38px */


color: #000000;
}



.headingimage1{
width: 84px;
height: 80.08px;
transform: translateX(25px) translateY(10px);
}

.headingimage2{
width: 71px;
height: 81.84px;
}

.ball1{
width: 130px;
height: 132.27px;
transform: translateX(22px) translateY(-17px);
filter: drop-shadow(0px 4px 61px rgba(0, 0, 0, 0.76));
animation-name: slowMoveUpDown;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slowMoveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

.ball2{
    /* position: absolute; */
width: 412px;
height: 433px;
left: 984px; 
top: 231px;
transform: translateX(90px) translateY(30px);


filter: drop-shadow(0px 4px 36px #000000);


}


.ball3{
    
width: 200px;
height: 181px;

position: absolute;
left: -3rem;
top: 17rem;



filter: drop-shadow(0px 4px 143px rgba(0, 0, 0, 0.72));
z-index: 10;
animation-name: slowMoveUpDown;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  transform: rotate(-49deg);

}
@keyframes slowMoveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }


.registertoday{
    z-index: 10;
width: 180px;
height: 96px;
left: 268px;
bottom: 4210px;
text-align: center;
background: #5274E3;
border: 5px solid #5274E3;
border-radius: 30px;
color: white;
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 30.04px;
/* line-height: 14px; */
/* text-indent: 25px; */
text-transform: uppercase;
}

/* .getfree{
    font-family: 'Denk One';
font-style: normal;
font-weight: 400;
font-size: 27.04px;
 line-height: 10px; 
width: 228px;
height: 174px;
left: 506px;
top: 462px;
color: white;
background: #000000;
border: 3px solid #000000;
text-align: center;
}  */


.bgimage{
   background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 4rem;
  padding-top: 2rem;
    background-image: url('./assets/services.png');
}

.pricingsection1heading
{
    /* position: absolute; */

font-family: 'Inter';
font-style: normal;
font-weight: 900;
font-size: 72px;
line-height: 62px;
/* or 87% */


color: rgba(254, 52, 8, 0.8);
}
.pricingsection1heading span{
    color: #325DEB;
}

.pricingsection1heading2{
    /* position: absolute; */



font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 33px;
}
.pricingsection1heading2 span{
    color: #325DEB;
}
.enroll-button{
    width: 126.45px;
height: 46.48px;

/* z-index: 9999; */
background: #1E214E;
border-radius: 20px;
}
.enroll-button h1{
    width: 95px;
height: 23px;


font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 45px;
/* or 118% */
text-align: center;
margin: 0 auto;


color: #FEFFFF;
}
.our-service-button{
    box-sizing: border-box;

/* position: absolute; */
width: 126.45px;
height: 46.48px;
left: 394px;
top: 495px;

background: #FEFFFF;
border: 1px solid #06C69A;
border-radius: 20px;
}

.our-service-button h1{
   
width: 74.45px;
height: 22.67px;

text-align: center;
margin: 0 auto;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 40px;
/* or 200% */


color: #000000;
}
.rectangle2{
  position: absolute;
  width: 250.96px;
  height: 485.51px;
  left: -146px;
  top: -23.1px;

  z-index: 1;
  background: #7895F3;
  border-radius: 127px;
  transform: matrix(0.81, -0.59, 0.62, 0.79, 0, 0);
}
.rectangle3{
    
/* position: absolute; */
position: absolute;
width: 171.09px;
height: 165.38px;
left: -72px;
top: 26px;
z-index: 7;
border-radius: 100%;
background: rgba(82, 116, 227, 0.84);
}
.rectangle4{
  position: absolute;
  width: 219px;
  height: 211.69px;
  left: -97px;
  top: 0px;
  z-index: 5;
  border-radius: 100%;
  background: #869ADC;
}
.rectangle5{
    
  position: absolute;
  width: 53.75px;
  height: 126.29px;
  left: 117px;
  top: 279px;
  z-index: 10;
  background: #5274E3;
  border-radius: 46px;
  transform: matrix(0.71, -0.7, 0.73, 0.69, 0, 0);
}
.rectangle6{
  position: absolute;
  width: 53.75px;
  height: 126.29px;
  left: -15rem;
  top: 0rem;
  
  background: #5274E3;
  border-radius: 46px;
  transform: matrix(0.71, -0.7, 0.73, 0.69, 0, 0);
}
.rectangle7{
    /* position: absolute; */
    position: absolute;
    width: 186.87px;
    height: 185.09px;
    left: -13rem;
    top: 17rem;
    border-radius: 100%;
    background: #7895F3;
    transform: matrix(0.98, 0.19, 0.21, -0.98, 0, 0);
}
.rectangle8{
    /* position: absolute; */
width: 437px;
height: 390px;
left: 644px;
top: 65px;
border: none;

background: url('./assets/Services/photo1683538695\ \(1\).webp');
border-radius: 797.5px;
}

.objective{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 21px;
/* identical to box height, or 105% */


color: #5D5D70;
}
.objectivep{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 21px;
/* or 140% */


color: #5D5D70;
}

.service-image8{
  /* position: absolute; */
  /* position: fixed; */
width: 440px;
height: 390px;
left: 39rem;
top: 121px;
z-index: 10;
    /* background: url('./assets/olav-ahrens-rotne-4Ennrbj1svk-unsplash\ 1.svg'); */
    /* /* border-radius: 797.5px; */
} 
.rectangle9{
  position: absolute;
  width: 175px;
  height: 151px;
  left: -63px;
  top: 15rem;

background: #1E214E;
border-radius: 95%;
z-index: 100;
}


.boxcontent{
    /* position: absolute; */
width: 107.69px;
height: 52.72px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 25px;

margin: 0 auto;
/* or 80% */
text-align: center;

margin-top: 3rem;

color: #FFFFFF;
}

h1 span{
    color: #325DEB;
  
}


.pricingsection1{
    /* position: absolute; */



font-family: 'Inter';
font-style: normal;
font-weight: 900;
font-size: 72px;
line-height: 62px;
/* or 87% */


color: rgba(254, 52, 8, 0.8);
}


/* faq */
.bgimage2{
    height: 10rem;
    /* z-index: -1; */
       background-size: cover;
   background-image: url('./assets/services.png');
}


.book-now{


    box-sizing: border-box;

    position: absolute;
    width: 172.28px;
    height: 42.32px;
    left: 162px;
    top: 371px;
    text-align: center;
    /* margin: 0 auto; */
    background: #FFFFFF;
    border: 3px solid rgba(13, 251, 196, 0.6);
    border-radius: 20px;
    
}
.book-now h1{
    width: 66.78px;
height: 21.7px;
left: 900.41px;
top: 982.85px;

font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 13px;
line-height: 40px;
/* margin: 0 auto; */
/* or 154% */
color: #000000;
}
.starclass{
 
width: 26.71px;
height: 21.7px;
margin-top: 8px;
background: #FFFFFF;

}

.demoimage{
width: 464.76px;
height: 414px;


background: url('./assets/demo.svg');
}

.demo{
   
/* width: 428.7px;
height: 86.82px; */


font-family: 'Inter';
font-style: normal;
font-weight: 900;
font-size: 40px;
line-height: 45px;
/* or 112% */


color: #325DEB;
}

.demo span{
    color:black
}

.demopara{
/*     
width: 502.15px;
height: 65.11px; */


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
/* or 167% */


color: #5D5D70;
}
.demopara span{
  font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 15px;
line-height: 20px;
/* identical to box height, or 133% */


color: #5D5D70;

}

.groupimage{
    /* position: absolute; */
    width: 501.79px;
    height: 482.24px;
    left: 712px;
    top: 1094px;
    
    
    border-radius: 81px;
background: url('./assets/group.svg');
}

.book-now2{
    
    box-sizing: border-box;

    position: absolute;
    width: 172.28px;
    height: 42.32px;
    left: 162px;
    top: 471px;
    text-align: center;
    /* margin: 0 auto; */
    background: #FFFFFF;
    border: 3px solid rgba(13, 251, 196, 0.6);
    border-radius: 20px;
}
.book-now2 h1{
    width: 66.78px;
height: 21.7px;
left: 900.41px;
top: 982.85px;

font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 13px;
line-height: 40px;
/* margin: 0 auto; */
/* or 154% */
color: #000000;
}

.rectanglegreen{
    position: absolute;
width: 140px;
height: 180px;
left: -75px;
top: 550px;
border-radius: 100%;
background: #03EDAE;
}
.rectangleblue{
    
position: absolute;
width: 140px;
height: 180px;
right: -45px;
top: 1104px;


border-radius: 100%;
background: #1E214E;
}

.oneoneimage{
    
width: 464.76px;
height: 379.83px;


background: url('./assets/one\ on\ one.svg');
}
.rectanglegreen2{
    position: absolute;
width: 140px;
height: 180px;
border-radius: 100%;
top: 1730px;
left: -75px;
background: #03EDAE;
}



.book-now3{
    box-sizing: border-box;

    position: absolute;
    width: 172.28px;
    height: 42.32px;
    left: 162px;
    top: 250px;
    text-align: center;
    /* margin: 0 auto; */
    background: #FFFFFF;
    border: 3px solid rgba(13, 251, 196, 0.6);
    border-radius: 20px;
}
.book-now3 h1{
    width: 66.78px;
height: 21.7px;
left: 900.41px;
top: 982.85px;

font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 13px;
line-height: 40px;
/* margin: 0 auto; */
/* or 154% */
color: #000000;
}

.aboutus{
    /* position: absolute; */
width: 285px;
height: 90px;
left: 989px;
top: 715px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 66px;
line-height: 90px;
/* identical to box height */
text-align: center;
letter-spacing: -0.02em;
margin: 0 auto;
color: #000000;
}

.orderedlist{
    /* position: absolute; */
/* width: 606px;
height: 138px;
left: 856px;
top: 804px; */

font-family: 'Josefin Sans';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 50px;
/* or 250% */


color: #000000;
}

.aboutimage{
    /* position: absolute; */
width: 617px;
height: 647px;


background: url('./assets//questionmark.svg');
}
.aboutimage1{
    /* position: absolute; */
width: 592px;
height: 221.38px;


background: url('./assets/cubechampsofficiallogo\ 2.svg');

}

.canopy{
    /* position: absolute; */
/* width: 657px;
height: 89px; */
/* left: 432px;
top: 2095px; */

font-family: 'Inter';
font-style: normal;
font-weight: 900;
font-size: 72px;
line-height: 62px;
/* or 87% */
margin: 0 auto;

color: #325DEB;
}

.canopy span{
    color: black;
}




.service-image-1{
    /* position: absolute; */
/* width: 477.17px;
height: 421.32px; */
/* left: 198px;
top: 705px; */

/* background: url(photo1683535352.jpg); */
border-radius: 81px;
}

.service-grid-heading{
    /* position: absolute; */
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 45px;
    /* identical to box height, or 112% */
    
    
    color: #325DEB
} 
.service-grid-heading span{
    font-family: 'IM FELL Great Primer';
font-style: italic;
font-weight: 400;
font-size: 20px;
line-height: 45px;
color: #1B224B;
}

.service-book{
    
    box-sizing: border-box;

/* position: absolute; */
width: 176.88px;
height: 48.03px;
left: 882.81px;
top: 1060.27px;

background: #FFFFFF;
border: 3px solid rgba(13, 251, 196, 0.6);
border-radius: 20px;
}

.bookheading{
    /* position: absolute; */
width: 68.56px;
height: 24.63px;
left: 929.43px;
top: 1072.58px;

font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 13px;
/* line-height: 20px; */
/* or 154% */
/* margin-top: 2rem; */


color: #000000;
}


.servicelastsectionheading{
    width: 897px;
    height: 62px;
 
    
    font-family: 'Inter';
    font-style: italic;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    /* or 125% */
    
    
    color: #000000;
}

.servicelastsectionsubheading{
    width: 794px;
height: 178px;


font-family: 'Inter';
font-style: italic;
font-weight: 400;
font-size: 18px;
line-height: 30px;
/* or 167% */


color: #000000;
}























.form-container {
    max-width: 1000px;
    /* margin: 0 auto; */
/* margin-top: 100px; */
margin-bottom: 100px;
    padding: 40px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  h2 {
    font-size: 26px;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .enrollment-form .form-group {
    margin-bottom: 20px;
  }
  
  .enrollment-form label {
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
    color: #333;
  }
  
  .enrollment-form input,
  .enrollment-form textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .enrollment-form textarea {
    height: 120px;
    resize: vertical;
  }
  
  .enrollment-form button[type='submit'] {
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .enrollment-form button[type='submit']:hover {
    background-color: #0056b3;
  }
  
  
.footer-location{
  overflow-y: hidden;
  overflow-x: hidden;
    /* width: 360px;
height: 44px; */

font-family: 'DM Sans', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 22px;
/* or 116% */


color: #FFFFFF;
}

.social-icon{
    
/* width: 68px;
height: 18px; */

font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
/* line-height: 18px; */
text-transform: uppercase;

color: #FFFFFF;
}

.background-contact{
    background-image: url('../src/assets/backgroundcontact.jpg');
    padding-bottom: 1rem;
    background-size: cover; /* Adjusts the image to cover the container */
   background-position: center;
    
}


.contact-us{

margin-top: 5rem;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 60px;
line-height: 50px;
}
.contact-box{
    width: 482px;
height: 286px;
left: 644px;
top: 256px;

background: rgba(217, 217, 217, 0.72);
border: 1px solid #325DEB;
z-index: 10;
box-shadow: 0px 4px 170px rgba(0, 0, 0, 0.25);
/* filter: blur(2px); */
}
  
.contact-heading{
    font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 22px;
/* or 116% */


color: #325DEB;
}
  
  
  .chat{
    font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 39px;
line-height: 22px;
  }

  .background-contact1{
    background-image: url('../src/assets/backgroundcontact.jpg');
    padding-bottom: 1rem;
   background-size: cover; /* Adjusts the image to cover the container */
  background-position: center;
  }
  


  .image-border{
    width: 200px;
    height: 200px;
    background: white;
    background-image: linear-gradient(to right, #7A8BC5, #03EDAE);
    border-radius: 50%;
  }


  .review-card{
    width: 350px;
height: 620px;


z-index: 1;

background: linear-gradient(179.69deg, #B4D6EE -31.53%, #325DEB 303.75%);
border-radius: 20px;
  }


  .review-name{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
/* identical to box height */

text-align: center;
text-transform: capitalize;

color: #F65F5F;
  }

  .reviews{
    
font-family: 'Biryani';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 23px;
text-align: center;

color: #000000;
  }


  .container-image{
background-image: url('../src/assets/backgroundcontact.jpg'); /* Set the background image */
  background-size: cover; /* Adjusts the image to cover the container */
  background-position: center;
 
  }


  .privacy-background{
    background-image: url('../src/assets/Contact Us/privacy1.png'); /* Set the background image */
  background-size: cover; /* Adjusts the image to cover the container */
  background-position: center;
  }



  .heading-privacy{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 70px;
line-height: 85px;
text-align: center;

color: #FFFFFF;
  }




  .navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid transparent;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  
  .navbar.sticky {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom-color: #e5e5e5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }



  .privacy-heading{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 31px;
line-height: 38px;
/* identical to box height */

text-transform: capitalize;

color: #000000;
  }


  .privacy-subheading{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;

color: #000000;
  }


  






























  .navbar-container {
    position: fixed;
    top: 0;
    padding-bottom: 4px;

   right: 0;
    /* bottom: 0; */
    z-index: 9999;
    width: 100%;
    transition: background-color 300ms, border-bottom-color 300ms;
    border-bottom: 1px solid transparent;
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .navbar-container.scrolled {
    background-color: #ffffff;
    border-bottom-color: #000000;
  }
  
  .navbar-content {
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
  }
  
  .navbar-logo {
    flex: 0 0 auto;
  }
  
  .logo {
    width: 215.05px;
    height: 80.42px;
  }
  
  .navbar-links {
    display: flex;
    gap: 3rem;
    margin-top: 35px;
  }
  
  .navbar-link {
    position: relative;
    
  }
  
  .navbar-box {
    position: relative;
  }
  
  .navbar-demo {
    margin-top: 5px;
  }
  
  .navbar-hamburger {
    display: none;
    margin-top: 25px;
  }
  
  @media only screen and (max-width: 1024px) {

    .navbar-link.hidden {
      display: none;
    }
    .whyus{

      gap: 4rem;
        display: flex;
        flex-direction: column-reverse;
    }
    .container-image{
      margin-top: 10rem;
    }
    .text-content1{
      width: 100%;
      /* margin: 0 auto; */
    }
    
    .heading{
        font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.02em;
    
    color: rgba(0, 0, 0, 0.99);
    }
    .heading1{
      font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #325DEB;
  /* color: rgba(0, 0, 0, 0.99); */
  }
   

    
    .subheading{
        font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 140%;
    /* or 38px */
    
    
    color: #000000;
    }
    
    
    
    .headingimage1{
    width: 70px;
    height: 68px;
    transform: translateX(25px) translateY(10px);
    }
    
    .headingimage2{
        width: 70px;
        height: 68px;
    }
    
    .ball1{
    width: 100px;
    height: 90px;
    transform: translateX(22px) translateY(-17px);
    filter: drop-shadow(0px 4px 61px rgba(0, 0, 0, 0.76));
    animation-name: slowMoveUpDown;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
    
    @keyframes slowMoveUpDown {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
        100% {
          transform: translateY(0);
        }
      }
    
    .ball2{
        /* position: absolute; */
    width: 300px;
    height: 330px;
    margin-left: -5rem;
    transform: translateX(90px) translateY(30px);
    
    
    filter: drop-shadow(0px 4px 36px #000000);
    
    
    }
    
    
    .ball3{
        
        width: 100px;
        height: 90px;
    
    position: absolute;
    left: -3rem;
    top: 14rem;
    
    
    
    filter: drop-shadow(0px 4px 143px rgba(0, 0, 0, 0.72));
    z-index: 10;
    animation-name: slowMoveUpDown;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transform: rotate(-49deg);
    
    }
    @keyframes slowMoveUpDown {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
        100% {
          transform: translateY(0);
        }
      }
    
    
    .registertoday{
        z-index: 10;
    width: 180px;
    height: 96px;
    left: 268px;
    bottom: 4210px;
    text-align: center;
    background: #5274E3;
    border: 5px solid #5274E3;
    border-radius: 30px;
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30.04px;
    /* line-height: 14px; */
    /* text-indent: 25px; */
    text-transform: uppercase;
    }
    
    /* .getfree{
        font-family: 'Denk One';
    font-style: normal;
    font-weight: 400;
    font-size: 27.04px;
     line-height: 10px; 
    width: 228px;
    height: 174px;
    left: 506px;
    top: 462px;
    color: white;
    background: #000000;
    border: 3px solid #000000;
    text-align: center;
    }  */
    
    
    .bgimage{
         height: 29rem;
         /* z-index: -1; */
            background-size: cover;
        background-image: url('./assets/services.png');
    }
    

    .section2{
        display: flex;
        flex-direction: column;
        margin-top: -15rem;
       
    }
    
    
    
    .navbar-hamburger {
      display: block;
    }
  
    .navbar-links {
      display: none;
    }
  
    .navbar-box {
      display: none;
    }
  }
  
  .navbar-mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px;
    align-items: center;
 

  backdrop-filter: blur(8px);
  

  }
  
  @media only screen and (min-device-width: 828px) and (orientation: portrait) {
    /* Styles specific to iPhone 11 portrait mode */
    .logo {
      width: 150px;
      height: 55px;
    }
  }
  
  @media only screen and (min-device-width: 1792px) and (orientation: landscape) {
    /* Styles specific to iPhone 11 landscape mode */
    .logo {
      width: 150px;
      height: 55px;
    }
  }
  

  @media only screen and (max-width: 840px){
    .reviews {
      font-family: 'Biryani';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: #000000;
  }
    .registerbuttoncss{
      display: none;
    }
    .mediabuttonphone{
      display: block;
    }
    .homepage-icon{
      display: flex;
      flex-direction: column-reverse;
      gap: 4rem;
      margin: 0 auto;
    }
    
    .footer-col{
      flex-direction: column;
      display: flex;
      margin: 0 auto;
    }
    .social-media-queries{
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      
    }
    .rectangle9{
      position: absolute;
      width: 175px;
      height: 151px;
      left: -20px;
      top: 15rem;
    
    background: #1E214E;
    border-radius: 95%;
    
    }
    
.bgimage{
  /* margin-top: -5rem; */
  background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
 height: 60rem;
   background-image: url('./assets/services.png');
}

    .new-service-col{
      display: flex;
      flex-direction: column-reverse;
      margin: 0 auto;
      
    }
    .gridcheck{
      display: grid;
      grid-template-columns: repeat(2,1fr);
    }
    .container-image{
      margin-top: 10rem;
    }
    .text-content1{
      width: 100%;
      /* margin: 0 auto; */
    }
    /* .ball1{
        
        display: none;
    }
    .ball3{
        display: none;

    }
    .ball2{
        display: none;
    } */
    .ellipse{
    
        position: absolute;
        width: 500px;
        height: 300px;
        left: 0px;
        top: 100px;
        
        background: #1AB5DB;
        filter: blur(397px);
        }
        .mobile{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 1rem;
        }

        .heading{
            font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 90px;
        letter-spacing: -0.02em;
        
        color: rgba(0, 0, 0, 0.99);
        }
        .heading1{
          font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 38px;
      line-height: 90px;
      letter-spacing: -0.02em;
      color: #325DEB;
      /* color: rgba(0, 0, 0, 0.99); */
      }
        
        .subheading{
            font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 140%;
        /* or 38px */
        
        
        color: #000000;
        }
        
        
        
        .headingimage1{
        width: 84px;
        height: 80.08px;
        transform: translateX(25px) translateY(10px);
        }
        
        .headingimage2{
        width: 71px;
        height: 81.84px;
        }

    


  }
  


  @media only screen and (max-width: 640px){
   
    .pricing-service{
      display: flex;
      flex-direction: column;
    }
    .pricing-service1{
      display: flex;
      flex-direction: column-reverse;
    }
    .button{
      
      width: 110px;
      height: 50px;
      text-align: center;
      /* margin-top: 10px; */
      margin: 0 auto;
      background: rgba(0, 0, 0, 0.99);
      box-shadow: 0px 0.73544px 1.47088px rgba(16, 24, 40, 0.05);
      border-radius: 5.88352px;
      color:white;
      }
    .gridcheck{
      display: flex;
      flex-direction: column;
      /* grid-template-columns: repeat(2,1fr); */
    }
    .container-image{
      margin-top: 10rem;
    }
    .orderedlist{
        /* position: absolute; */
    /* width: 606px;
    height: 138px;
    left: 856px;
    top: 804px; */
    
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 50px;
    /* or 250% */
    
    
    color: #000000;
    }
    .contact-mobile{
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }
    .contactmobile2{
      width: 100%;
      margin:  0 auto;
     
    }
    .ok{
      max-width: 100%;
      height: auto;
      width: auto\9;
    }


    .services-button{
      padding: 0;
    }

    /* .serviceflexcol{
      display: flex;
      flex-direction: column-reverse;
    } */
  }

.menu-text{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 21px;
/* identical to box height */


color: #FFFFFF;

mix-blend-mode: normal;
opacity: 0.5;
}

  @media (min-width: 522px) {
    #menu-text {
      display: none;
    }
  }
  
 

  @media only screen and (max-width: 521px){
    
    .footer-location6{
      overflow-y: hidden;
      overflow-x: hidden;
      font-family: 'DM Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #FFFFFF
    }
    .object{
      margin: 0 auto;
    }
    .iolocation {
      width: 28px;
      height: 28px;
    }
    .socialcolumn {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
    #menu-text {
      display: block;
    }
    .menu{
      visibility: visible;
    }
    .pricingsection1heading
    {
        /* position: absolute; */
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 62px;
    /* or 87% */
    
    
    color: rgba(254, 52, 8, 0.8);
    }
    .pricingsection1heading2{
      /* position: absolute; */
  
  
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  }
  .star1{
    position: absolute;
    top: 3rem;
    right: -1.5rem;

  }
  .service-grid {
    display: flex;
    flex-direction: column;
  }

  .service-image8{
    /* position: absolute; */
    /* position: fixed; */
  width: 276px;
  height: 390px;
  left: 39rem;
  top: 121px;
  z-index: 10;
      /* background: url('./assets/olav-ahrens-rotne-4Ennrbj1svk-unsplash\ 1.svg'); */
      /* /* border-radius: 797.5px; */
  }

  .rectangle2{
  position: absolute;
  width: 154.96px;
    height: 402.51px;
    left: -169px;
    top: -16.1px;

  z-index: 1;
  background: #7895F3;
  border-radius: 127px;
  transform: matrix(0.81, -0.59, 0.62, 0.79, 0, 0);
}
.rectangle3{
    
/* position: absolute; */
position: absolute;
width: 171.09px;
height: 165.38px;
left: -136px;
top: 26px;
z-index: 7;
border-radius: 100%;
background: rgba(82, 116, 227, 0.84);
}
.rectangle4{
  position: absolute;
  width: 219px;
  height: 211.69px;
  left: -162px;
  top: 0px;
  z-index: 5;
  border-radius: 100%;
  background: #869ADC;
}
.rectangle5{
    
  position: absolute;
  width: 35.75px;
  height: 97.29px;
  left: 7px;
  top: 268px;
  z-index: 10;
  background: #5274E3;
  border-radius: 46px;
  transform: matrix(0.71, -0.7, 0.73, 0.69, 0, 0);
}
.rectangle6{
  position: absolute;
  width: 53.75px;
  height: 126.29px;
  left: -15rem;
  top: 0rem;
  
  background: #5274E3;
  border-radius: 46px;
  transform: matrix(0.71, -0.7, 0.73, 0.69, 0, 0);
}
.rectangle7{
    /* position: absolute; */
    position: absolute;
    width: 186.87px;
    height: 185.09px;
    left: -13rem;
    top: 17rem;
    border-radius: 100%;
    background: #7895F3;
    transform: matrix(0.98, 0.19, 0.21, -0.98, 0, 0);
}
.rectangle8{
    /* position: absolute; */
width: 437px;
height: 390px;
left: 644px;
top: 65px;
border: none;

background: url('./assets/Services/photo1683538695\ \(1\).webp');
border-radius: 797.5px;
}

.contact-us{

  margin-top: 5rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  }
  .contact-box{
      width: 352px;
  height: 286px;
  left: 644px;
  top: 256px;
  
  background: rgba(217, 217, 217, 0.72);
  border: 1px solid #325DEB;
  z-index: 10;
  box-shadow: 0px 4px 170px rgba(0, 0, 0, 0.25);
  /* filter: blur(2px); */
  }

  .whatsappbox{
    width: 348px;
  }
  .chat{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
  }
  .enrollment-form label {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
    color: #333;
  }

  .textofchoice{
    gap: 10px;
  }
  .canopy{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 62px;
    margin: 0 auto;
    color: #325DEB;
  }
  .formcss{
    display: flex;
    flex-direction: column;
  }
  .formcsstext{
    width: 100%;
  }
  .social-media-queries{
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: center;
   
    
  }
  .navlinksfooter{
    flex-direction: column;
    display: flex;
    margin: 0 auto;
  }

  .footer-location2{
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
  } 
  .pricingsection1heading {
    color: rgba(254,52,8,.8);
    font-family: Inter;
    font-size: 44px;
    font-style: normal;
    font-weight: 900;
    line-height: 62px;
}
.footer-image{
  display: block;

}
.addressmedia{
  display: none;
}
.ball1{
  /* flex-shrink: 0; */
  width: 100px;
  height: 90px;
  margin-left: -2rem;
  transform: translateX(22px) translateY(-17px);
  filter: drop-shadow(0px 4px 61px rgba(0, 0, 0, 0.76));
  animation-name: slowMoveUpDown;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  @keyframes slowMoveUpDown {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  
  .ball2{
      /* position: absolute; */
      max-width: 100%;
      height: auto;
      width: auto\9;
  margin-left: -5rem;
  transform: translateX(90px) translateY(30px);
  
  
  filter: drop-shadow(0px 4px 36px #000000);
  
  
  }
  
  
  .ball3{
      
      width: 100px;
      height: 90px;
  
  position: absolute;
  left: -6rem;
  top: 14rem;
  
  
  
  filter: drop-shadow(0px 4px 143px rgba(0, 0, 0, 0.72));
  z-index: 10;
  animation-name: slowMoveUpDown;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    transform: rotate(-49deg);
  
  }
  @keyframes slowMoveUpDown {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  
    .homepage-icon{
      gap: 10rem;
    }
    .subheading {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
  }
 
}




.hello {

  background-image: url(https://cdn.thecodehelp.in/f2y7na7cqmsrdspozkwo_0cebdff421.webp);
  background-repeat: no-repeat;
  width: 100%;
height: 500px; 
align-items: center;
justify-content: center;
display: flex;
background-position: center;
/* You may want to specify additional CSS properties for the background image */
/* For example: background-size, background-repeat, etc. */
}